import { NodeProps } from "reactflow";
import { findResource } from "../../../utils/classes/configuration";
import { ProcessorCardV2 } from "../../Cards/ProcessorCardV2";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName, V2NodeData } from "../types";

export const ProcessorListNode: React.FC<NodeProps<V2NodeData>> = ({
  data,
  id,
}) => {
  const { attributes } = data;
  const { setHoveredSet, configuration, editProcessors } = useV2PipelineGraph();

  if (!configuration) {
    return null;
  }

  const resourceConfig = findResource(
    configuration,
    attributes[AttributeName.ComponentPath],
  );

  return (
    <RoutingNodeWrapper
      componentType={"processors"}
      componentPath={attributes[AttributeName.ComponentPath]}
    >
      <div
        onMouseEnter={() => {
          setHoveredSet(data.connectedNodesAndEdges);
        }}
        onMouseLeave={() => setHoveredSet([])}
      >
        <ProcessorCardV2
          nodeId={id}
          onClick={() =>
            editProcessors(attributes[AttributeName.ComponentPath])
          }
          processorCount={resourceConfig?.processors?.length ?? 0}
        />
      </div>
    </RoutingNodeWrapper>
  );
};
