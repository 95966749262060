import { ApolloProvider } from "@apollo/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import APOLLO_CLIENT from "./apollo-client";
import { edition } from "./components/BindplaneVersion/utils";
import {
  AgentInstallPage,
  AgentPage,
  AgentsPage,
  ConfigurationsPage,
  NewConfigurationPage,
} from "./pages";
import { AcceptInvitationPage } from "./pages/accept-invitation";
import { AccountPage, SetupAccountPage } from "./pages/account";
import { AuditLogsPage } from "./pages/audit-logs/AuditLogsPage";
import { ConfigurationPage } from "./pages/configurations/configuration";
import { NewRawConfigurationPage } from "./pages/configurations/new-raw";
import { EulaRequiredPage } from "./pages/eula-required/EulaRequiredPage";
import { LicenseRequiredPage } from "./pages/license-required/LicenseRequiredPage";
import { LoginPage } from "./pages/login";
import { SetupOrganizationPage } from "./pages/organizations/new/NewOrganization";
import { ViewOrganizationPage } from "./pages/organizations/view/ViewOrganization";
import { OverviewPage } from "./pages/overview/OverviewPage";
import { SummaryPage } from "./pages/overview/SummaryPage";
import { ResourceLibraryPage } from "./pages/resource-library/ResourceLibraryPage";
import { defaultTheme, honeycombTheme } from "./theme";
import { AuthType } from "./types/auth";
import { getAuthType } from "./utils/get-auth-type";
import "reactflow/dist/style.css";

export const App: React.FC = () => {
  const googleClientId = getGoogleClientId();
  const theme = edition() === "Honeycomb" ? honeycombTheme : defaultTheme;

  useEffect(() => {
    if (edition() === "Honeycomb") {
      updateHeadForHoneycomb();
    }
  }, []);

  return (
    <div
      className={
        edition() === "Honeycomb" ? "theme--honeycomb" : "theme--default"
      }
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={APOLLO_CLIENT}>
            <GoogleOAuthProvider clientId={googleClientId}>
              {appWithAuthProvider(
                <SnackbarProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <BrowserRouter>
                      <QueryParamProvider adapter={ReactRouter6Adapter}>
                        <Routes>
                          {/* Unauthenticated Routes */}
                          <Route path="/login" element={<LoginPage />} />
                          <Route
                            path="/signup"
                            element={<LoginPage defaultAction="signup" />}
                          />
                          <Route
                            path="/accept-invitation"
                            element={<AcceptInvitationPage />}
                          />

                          {/** App Routes */}
                          <Route path="/overview">
                            <Route index element={<OverviewPage />} />
                            <Route path="summary" element={<SummaryPage />} />
                          </Route>
                          <Route
                            path="/"
                            element={<Navigate to="/overview" />}
                          />
                          <Route path="agents">
                            <Route index element={<AgentsPage />} />
                            <Route
                              path="install"
                              element={<AgentInstallPage />}
                            />
                            <Route path=":id">
                              <Route index element={<AgentPage />} />
                            </Route>
                          </Route>
                          <Route path="configurations">
                            <Route index element={<ConfigurationsPage />} />
                            <Route
                              path="new-raw"
                              element={<NewRawConfigurationPage />}
                            />
                            <Route
                              path="new"
                              element={<NewConfigurationPage />}
                            />
                            <Route
                              path=":name"
                              element={<ConfigurationPage />}
                            />
                          </Route>
                          <Route path="resource-library">
                            <Route index element={<ResourceLibraryPage />} />
                          </Route>
                          <Route path="project">
                            <Route index element={<AccountPage />} />
                            <Route
                              path="/project/new"
                              element={<SetupAccountPage />}
                            />
                          </Route>
                          <Route path="organization">
                            <Route
                              path="new"
                              element={<SetupOrganizationPage />}
                            />
                            <Route index element={<ViewOrganizationPage />} />
                          </Route>

                          <Route path="audit-logs">
                            <Route index element={<AuditLogsPage />} />
                          </Route>
                          <Route
                            path="license-required"
                            element={<LicenseRequiredPage />}
                          />
                          <Route
                            path="eula-required"
                            element={<EulaRequiredPage />}
                          />
                        </Routes>
                      </QueryParamProvider>
                    </BrowserRouter>
                  </LocalizationProvider>
                </SnackbarProvider>,
              )}
            </GoogleOAuthProvider>
          </ApolloProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
const history = createBrowserHistory();

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname,
  );
};

/**
 * Only wrap the app with Auth0Provider if the auth type is Auth0.
 * Otherwise we get an error on insecure origin
 */
const appWithAuthProvider: React.FC<React.ReactElement> = (children) => {
  const authType = getAuthType();
  if (authType === AuthType.Auth0) {
    return (
      <Auth0Provider
        domain={getAuth0Domain()}
        clientId={getAuth0ClientId()}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    );
  }
  return <>{children}</>;
};

function getGoogleClientId(): string {
  try {
    return __GOOGLE_CLIENT_ID__;
  } catch (err) {
    return "";
  }
}

function getAuth0Domain(): string {
  try {
    return __AUTH0_DOMAIN__;
  } catch (err) {
    return "";
  }
}

function getAuth0ClientId(): string {
  try {
    return __AUTH0_CLIENT_ID__;
  } catch (err) {
    return "";
  }
}

function updateHeadForHoneycomb() {
  const link = document.querySelector("link[rel*='icon']");
  if (link) {
    (link as HTMLLinkElement).href = "/honeycomb-favicon.ico";
  }

  const title = document.querySelector("title");
  if (title) {
    title.textContent = "Honeycomb Telemetry Pipeline Management";
  }
}
