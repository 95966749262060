import { CircularProgress, Stack } from "@mui/material";
import { useEffect } from "react";
import ReactFlow, {
  Controls,
  useEdgesState, // useNodes,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "reactflow";
import { Role } from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import colors from "../../styles/colors";
import {
  GRAPH_NODE_OFFSET,
  GRAPH_PADDING,
  Page, // updateMetricData,
} from "../../utils/graph/utils";
import { hasPermission } from "../../utils/has-permission";
import { ConfigDetailsMenu } from "../ConfigDetailsMenu";
import { DummyProcessorNodeV2 } from "../PipelineGraph/Nodes/DummyProcessorNodeV2";
import UIControlNode from "../PipelineGraph/Nodes/UIControlNode";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import ConfigurationEdgeV2 from "../PipelineGraphV2/Components/ConfigurationEdgeV2";
import { ConnectorNode } from "../PipelineGraphV2/Components/ConnectorNode";
import DestinationNodeV2 from "../PipelineGraphV2/Components/DestinationNodeV2";
import { ProcessorListNode } from "../PipelineGraphV2/Components/ProcessorListNode";
import { ProcessorNodeV2 } from "../PipelineGraphV2/Components/ProcessorNodeV2";
import { RoutingConnectorNodeV2 } from "../PipelineGraphV2/Components/RoutingConnectorNodeV2";
import SourceNodeV2 from "../PipelineGraphV2/Components/SourceNodeV2";
import { useV2PipelineGraph } from "../PipelineGraphV2/PipelineGraphV2Context";
import { layoutV2Graph } from "./layout";

const nodeTypes = {
  connectorNode: ConnectorNode,
  destinationNode: DestinationNodeV2,
  dummyProcessorNode: DummyProcessorNodeV2,
  processorListNode: ProcessorListNode,
  processorNode: ProcessorNodeV2,
  routingConnectorNode: RoutingConnectorNodeV2,
  sourceNode: SourceNodeV2,
  uiControlNode: UIControlNode,
};

const edgeTypes = {
  configurationEdge: ConfigurationEdgeV2,
};

export const TARGET_OFFSET_MULTIPLIER = 300;

interface ConfigurationFlowProps {
  period: string;
  selectedTelemetry: string;
  page: Page.Agent | Page.Configuration;
  loading?: boolean;
  configurationName: string;
}

/**
 * ConfigurationFlow renders the PipelineGraph for a configuration
 * @param period the period on which to query for metrics
 * @param selectedTelemetry the telemetry type on which to query for metrics
 * @param loading whether the graph is loading, will show a loading spinner if true
 * @param measurementData optional data from the ConfigurationMetrics subscription
 * @returns
 */
export const ConfigurationFlowV2: React.FC<ConfigurationFlowProps> = ({
  period,
  selectedTelemetry,
  loading,
  configurationName,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const reactFlowInstance = useReactFlow();
  const { width, height } = useStoreApi().getState();

  const {
    readOnlyGraph,
    configuration,
    setAddSourceOpen,
    setAddDestinationOpen,
  } = useV2PipelineGraph();

  const role = useRole();

  const viewPortHeight = getViewPortHeight(configuration);

  useEffect(() => {
    async function layoutGraph() {
      let { nodes, edges } = layoutV2Graph(
        configuration,
        !!readOnlyGraph,
        selectedTelemetry,
        () => setAddSourceOpen(true),
        () => setAddDestinationOpen(true),
      );

      setNodes(nodes);
      setEdges(edges);
    }
    layoutGraph();
  }, [
    configuration,
    period,
    readOnlyGraph,
    selectedTelemetry,
    setAddDestinationOpen,
    setAddSourceOpen,
    setEdges,
    setNodes,
  ]);

  useEffect(() => {
    // Fit the view to the graph when the window is resized
    const fitView = function () {
      reactFlowInstance.fitView();
    };

    window.addEventListener("resize", fitView);

    return () => window.removeEventListener("resize", fitView);
  }, [reactFlowInstance]);

  useEffect(() => {
    // Refit the view when our nodes have changed, i.e. one is deleted.
    // By placing this in a setTimeout with delay 0 we
    // ensure this is called on the next event cycle, not right away.
    setTimeout(() => reactFlowInstance.fitView(), 0);
  }, [nodes, reactFlowInstance]);

  useEffect(() => {
    // Refit the view window when the bounds of the react flow have changed.
    setTimeout(() => reactFlowInstance.fitView(), 0);
  }, [height, width, reactFlowInstance]);

  if (loading) {
    return (
      <div style={{ height: viewPortHeight, width: "100%" }}>
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          style={{ background: colors.backgroundWhite }}
        >
          <CircularProgress />
        </Stack>
      </div>
    );
  }

  return (
    <div style={{ height: viewPortHeight, width: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        // Called by a react flow node when entering the viewport,
        // use to refit when we add a destination or source.
        onNodesChange={(c) => {
          onNodesChange(c);
          reactFlowInstance.fitView();
        }}
        proOptions={{ account: "paid-pro", hideAttribution: true }}
        nodesConnectable={false}
        nodesDraggable={false}
        fitView={true}
        deleteKeyCode={null}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        panOnDrag={false}
        preventScrolling={false}
        // This is a hack to hide the graph from the viewport until
        // we have called a fitView on the reactFlowInstance.  This
        // is to prevent the graph from appearing out of view on
        // first load.
        defaultViewport={{ x: 1000, y: 1000, zoom: 1 }}
      >
        <Controls
          showZoom={false}
          showInteractive={false}
          showFitView={false}
          position="bottom-right"
        >
          <ConfigDetailsMenu
            data-testid="config-menu-button"
            configName={configurationName}
            iconProps={{ width: "20px", height: "20px" }}
            readOnly={readOnlyGraph || !hasPermission(Role.User, role)}
          />
        </Controls>
      </ReactFlow>
    </div>
  );
};

function getViewPortHeight(configuration: MinimumRequiredConfig) {
  return (
    GRAPH_PADDING +
    Math.max(
      configuration?.graph?.sources?.length || 0,
      configuration?.graph?.targets?.length || 0,
    ) *
      GRAPH_NODE_OFFSET
  );
}
