import { memo } from "react";
import { usePipelineGraph } from "../../../hooks/usePipelineGraph";
import { BPResourceConfiguration } from "../../../utils/classes";
import { CardMeasurementContent } from "../../CardMeasurementContent/CardMeasurementContent";
import { InlineSourceCard } from "../../Cards/InlineSourceCard";
import { ResourceSourceCard } from "../../Cards/ResourceSourceCard";
import { MinimumRequiredConfig } from "../PipelineGraph";
import { isNodeDisabled } from "./nodeUtils";

function SourceNode({
  data,
}: {
  data: {
    id: string;
    metric: string;
    attributes: Record<string, any>;
    connectedNodesAndEdges: string[];
    configuration: MinimumRequiredConfig;
    refetchConfiguration: () => void;
  };
}) {
  const {
    configuration,
    refetchConfiguration,
    readOnlyGraph,
    setHoveredSet,
    hoveredSet,
    selectedTelemetryType,
  } = usePipelineGraph();
  const { id, metric, attributes } = data;

  var sourceIndex = attributes["sourceIndex"];
  if (typeof sourceIndex !== "number") {
    sourceIndex = -1;
  }

  const resourceConfig = new BPResourceConfiguration(
    configuration!.spec!.sources![sourceIndex],
  );

  const isDisabled = isNodeDisabled(selectedTelemetryType, attributes);
  const isNotInHoverSet =
    hoveredSet.length > 0 && !hoveredSet.find((elem) => elem === data.id);

  return (
    <div
      onMouseEnter={() => {
        setHoveredSet(data.connectedNodesAndEdges);
      }}
      onMouseLeave={() => setHoveredSet([])}
    >
      {resourceConfig.isInline() ? (
        <InlineSourceCard
          id={id.replace("source/", "")}
          disabled={isDisabled || isNotInHoverSet}
          configuration={configuration}
          refetchConfiguration={refetchConfiguration}
          readOnly={!!readOnlyGraph}
          onDeleteSuccess={() => setHoveredSet([])}
        />
      ) : (
        <ResourceSourceCard
          name={resourceConfig.name!}
          disabled={isDisabled || isNotInHoverSet}
          sourceId={id}
          configuration={configuration}
          refetchConfiguration={refetchConfiguration}
          readOnly={!!readOnlyGraph}
          onDeleteSuccess={() => setHoveredSet([])}
        />
      )}
      <CardMeasurementContent>{metric}</CardMeasurementContent>
    </div>
  );
}

export default memo(SourceNode);
