import {
  MenuProps,
  Menu,
  ListItem,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { ComponentType } from "../../utils/classes/component-type";
import { findResource } from "../../utils/classes/configuration";
import { getNextComponentPaths } from "../../utils/classes/resource-configuration";
import { trimVersion } from "../../utils/version-helpers";
import { PlusCircleIcon, XIcon } from "../Icons";
import { useV2PipelineGraph } from "../PipelineGraphV2/PipelineGraphV2Context";
import { AttributeName, V2Config } from "../PipelineGraphV2/types";
import styles from "./routing-node-menu.module.scss";

interface RoutingNodeMenuProps extends MenuProps {
  componentType?: ComponentType;
  componentPath?: string;
  hasAvailableConnections: boolean;
  onConnectClick: (componentType: ComponentType, componentPath: string) => void;
  onRemoveComponentPath: (removePath: string) => void;
}

export const RoutingNodeMenu: React.FC<RoutingNodeMenuProps> = ({
  componentType,
  componentPath,
  hasAvailableConnections,
  onConnectClick,
  onRemoveComponentPath,
  ...menuProps
}) => {
  const { configuration, selectedTelemetryType } = useV2PipelineGraph();
  if (configuration == null) return null;

  if (componentType == null || componentPath == null) return null;

  const rc = findResource(configuration, componentPath);
  if (rc == null) throw new Error("Resource configuration not found");

  const componentPaths = getNextComponentPaths(rc, selectedTelemetryType);
  const { destinations, connectors, processors } =
    reduceNextComponents(componentPaths);

  let destinationMenuItems = destinations.reduce<MenuItem[]>((acc, d) => {
    const rc = findResource(configuration, d);
    if (rc == null) return acc;
    acc.push({ display: rc.readableName(), componentPath: d });
    return acc;
  }, []);

  destinationMenuItems = destinationMenuItems.sort(
    sortByTargetPosition(configuration),
  );

  let connectorMenuItems = connectors.reduce<MenuItem[]>((acc, c) => {
    const rc = findResource(configuration, c);
    if (rc == null) return acc;
    acc.push({ display: rc.readableName(), componentPath: c });
    return acc;
  }, []);

  let processorMenuItems = processors.reduce<MenuItem[]>((acc, p) => {
    const rc = findResource(configuration, p);
    if (rc == null) return acc;
    acc.push({ display: rc.readableName(), componentPath: p });
    return acc;
  }, []);

  return (
    <Menu {...menuProps} classes={{ paper: styles.menuPaper }}>
      {destinationMenuItems.length > 0 && (
        <ListSubheader>Destinations</ListSubheader>
      )}
      {destinationMenuItems.map(({ componentPath, display }) => (
        <ListItem
          key={componentPath}
          secondaryAction={
            <IconButton onClick={() => onRemoveComponentPath(componentPath)}>
              <XIcon width="15px" height={"15px"} />
            </IconButton>
          }
        >
          <ListItemText>{trimVersion(display)}</ListItemText>
        </ListItem>
      ))}
      {processorMenuItems.length > 0 && (
        <ListSubheader>Processors</ListSubheader>
      )}
      {processorMenuItems.map(({ componentPath, display }) => (
        <ListItem
          key={componentPath}
          secondaryAction={
            <IconButton onClick={() => onRemoveComponentPath(componentPath)}>
              <XIcon width="15px" height={"15px"} />
            </IconButton>
          }
        >
          <ListItemText>{trimVersion(display)}</ListItemText>
        </ListItem>
      ))}
      {connectorMenuItems.length > 0 && (
        <ListSubheader>Connectors</ListSubheader>
      )}
      {connectorMenuItems.map(({ componentPath, display }) => (
        <ListItem
          key={componentPath}
          secondaryAction={
            <IconButton onClick={() => onRemoveComponentPath(componentPath)}>
              <XIcon width="15px" height={"15px"} />
            </IconButton>
          }
        >
          <ListItemText>{trimVersion(display)}</ListItemText>
        </ListItem>
      ))}
      {
        <ListItem>
          <Tooltip
            title={hasAvailableConnections ? null : "No available connections"}
          >
            <span>
              <ListItemButton
                onClick={() => onConnectClick(componentType, componentPath)}
                disabled={!hasAvailableConnections}
              >
                <ListItemIcon>
                  <PlusCircleIcon />
                </ListItemIcon>
                <ListItemText>Add Connection</ListItemText>
              </ListItemButton>
            </span>
          </Tooltip>
        </ListItem>
      }
    </Menu>
  );
};

type MenuItem = {
  display: string;
  componentPath: string;
};

function reduceNextComponents(nextComponents: string[]): {
  destinations: string[];
  connectors: string[];
  processors: string[];
} {
  return nextComponents.reduce<{
    destinations: string[];
    connectors: string[];
    processors: string[];
  }>(
    (acc, cur) => {
      const componentType = cur.split("/")[0];
      switch (componentType) {
        case "destinations":
          acc.destinations.push(cur);
          break;
        case "connectors":
          acc.connectors.push(cur);
          break;
        case "processors":
          acc.processors.push(cur);
          break;
      }
      return acc;
    },
    { destinations: [], connectors: [], processors: [] },
  );
}

function sortByTargetPosition(
  configuration: V2Config,
): (a: MenuItem, b: MenuItem) => number {
  return (a, b) => {
    const aIndex = configuration?.graph?.targets?.findIndex(
      (t) => t.attributes[AttributeName.ComponentPath] === a.componentPath,
    );
    if (aIndex == null) return -1;
    const bIndex = configuration?.graph?.targets?.findIndex(
      (t) => t.attributes[AttributeName.ComponentPath] === b.componentPath,
    );
    if (bIndex == null) return 1;

    return aIndex - bIndex;
  };
}
