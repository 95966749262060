import { memo } from "react";
import { NodeProps } from "reactflow";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { findResource } from "../../../utils/classes/configuration";
import { ResourceDestinationCard } from "../../Cards/ResourceDestinationCard";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { useRouting } from "../RoutingContext/RoutingContext";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName, V2NodeData } from "../types";

const DestinationNodeV2: React.FC<NodeProps<V2NodeData>> = ({ id, data }) => {
  const {
    hoveredSet,
    setHoveredSet,
    selectedTelemetryType,
    configuration,
    refetchConfiguration,
    readOnlyGraph,
  } = useV2PipelineGraph();
  const { connectableComponentPaths } = useRouting();
  const { attributes } = data;
  const componentPath = attributes[AttributeName.ComponentPath];
  if (componentPath == null) {
    throw new Error("missing required attribute in destination node");
  }

  const isConnectable = connectableComponentPaths.includes(componentPath);
  const isInactive = !hasPipelineTypeFlag(
    selectedTelemetryType,
    attributes[AttributeName.ActiveTypeFlags],
  );
  const notInHoveredSet =
    hoveredSet.length > 0 && !hoveredSet.find((elem) => elem === id);

  if (configuration == null) {
    return null;
  }

  const resourceConfig = findResource(configuration, componentPath);
  if (resourceConfig == null) {
    return null;
  }

  return (
    <RoutingNodeWrapper
      componentType={"destinations"}
      componentPath={attributes[AttributeName.ComponentPath]}
    >
      <div
        onMouseEnter={() => {
          setHoveredSet(data.connectedNodesAndEdges);
        }}
        onMouseLeave={() => setHoveredSet([])}
      >
        <ResourceDestinationCard
          // Override the onClick so we don't open the edit dialog when trying to connect.
          onClick={isConnectable ? () => {} : undefined}
          key={id}
          destinationIndex={attributes[AttributeName.DestinationIndex]}
          name={attributes.resourceId}
          disabled={isInactive || notInHoveredSet}
          onDeleteSuccess={() => setHoveredSet([])}
          configuration={configuration}
          refetchConfiguration={refetchConfiguration}
          readOnly={!!readOnlyGraph}
        />
      </div>
    </RoutingNodeWrapper>
  );
};

export default memo(DestinationNodeV2);
