import { memo } from "react";
import { NodeProps } from "reactflow";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { findResource } from "../../../utils/classes/configuration";
import { InlineSourceCard } from "../../Cards/InlineSourceCard";
import { ResourceSourceCard } from "../../Cards/ResourceSourceCard";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName, V2NodeData } from "../types";

const SourceNodeV2: React.FC<NodeProps<V2NodeData>> = ({ id, data }) => {
  const {
    configuration,
    refetchConfiguration,
    selectedTelemetryType,
    hoveredSet,
    setHoveredSet,
    readOnlyGraph,
  } = useV2PipelineGraph();
  const { attributes } = data;
  const componentPath = attributes[AttributeName.ComponentPath];

  const isInactive = !hasPipelineTypeFlag(
    selectedTelemetryType,
    attributes[AttributeName.ActiveTypeFlags],
  );
  const notInHoveredSet = hoveredSet.length > 0 && !hoveredSet.includes(id);

  if (configuration == null) {
    return null;
  }

  const resourceConfig = findResource(configuration, componentPath);
  if (resourceConfig == null) {
    return null;
  }

  return (
    <RoutingNodeWrapper
      componentType="sources"
      componentPath={attributes[AttributeName.ComponentPath]}
    >
      <div
        onMouseEnter={() => {
          setHoveredSet(data.connectedNodesAndEdges);
        }}
        onMouseLeave={() => setHoveredSet([])}
      >
        {resourceConfig.isInline() ? (
          <InlineSourceCard
            id={id.replace("source/", "")}
            disabled={isInactive || notInHoveredSet}
            configuration={configuration}
            refetchConfiguration={refetchConfiguration}
            readOnly={!!readOnlyGraph}
          />
        ) : (
          <ResourceSourceCard
            name={resourceConfig.name!}
            disabled={isInactive || notInHoveredSet}
            sourceId={id}
            configuration={configuration}
            refetchConfiguration={refetchConfiguration}
            readOnly={!!readOnlyGraph}
          />
        )}
      </div>
    </RoutingNodeWrapper>
  );
};

export default memo(SourceNodeV2);
